<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: harvestYeild.vue
Description:This file gives the information about harvest details like soc,fcr ,biomass,abw,survival(%),doc
-->
<template>
  <layout-pondlogs headerType="card" containerType="card" class="culture-yield">
    <p class="layout-title" slot="custom-title">{{ $t("Comn_yield") }}</p>
    <template slot="header">
      <er-button
        size="mini"
        btnType="add"
        class="align-item-left"
        :disabled="isReadOnly"
        @click="
          handleClickAction($event, 'add', $lodash.cloneDeep(newHarvestLog))
        "
      >
        {{ $t("Harvest_load_a_harvest") }}
      </er-button>
      &nbsp;
      <er-input
        inputType="search"
        v-model="pondSearch"
        size="mini"
        class="input-search-pond"
        suffix-icon="el-icon-search"
        :placeholder="labelsAndPlaceHolder['search_pond'].placeholder"
        @change="handelSearchInput"
      ></er-input>
      <er-date-picker
        :clearable="false"
        type="daterange"
        unlink-panels
        :timeZoneString="getUserTimeZoneString"
        class="date-picker-harvest"
        v-model="date"
        :format="upm__getFormatDateString"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        size="mini"
        @change="handleDateChange"
      ></er-date-picker>
      &nbsp;
      <er-column-selector
        key-to-store-defaults="yield-harvest-table"
        :title="`Column ${$t('Comn_visibility')}`"
        :columns="tableColumns"
        @column-save="handelColumnSave"
        @change-selection="handleChangeInColumnSelection"
      ></er-column-selector>
    </template>

    <el-row
      v-loading="loading"
      element-loading-background="white"
      slot="layout-pondlogs-scrollable-main"
    >
      <el-row v-if="!loading">
        <er-data-tables
          ref="harvestyeildtable"
          v-show="!loading"
          :tableData="tableData"
          :columns="tableColumns"
          :action-column="false"
          :key="$i18n.locale"
          v-loading="loading"
          element-loading-background="white"
          :expand-column="true"
          type="white-header-table"
          unique-id="harvest-yield"
          :el-table-props="tableProps"
          :pagination-props="$constants.table['pagination-props']"
          :page-size="$constants.table['page-size']"
          :sortMethodForColumns="sortMethodForColumns"
          :selectedColumnHeaders="selectedHeaders"
          :selectedColumnHeadersSortable="true"
          @expand-change="handleExpandChange"
          :searchOnTableData="pondSearch"
        >
          <template v-slot:expandColumn="{ row }">
            <er-data-tables
              element-loading-background="white"
              v-loading="expandloading === row.data.pond_id._id"
              :tableData="getExpandedTableData(row.data)"
              :columns="expandedTableColumns"
              :el-table-props="tableProps_expand_table"
              :action-column="false"
              unique-id="harvest-yield-inner-table"
              type="white-header-table-inner"
              layout="table"
            >
              <template v-slot:start_of_cultivation="{ row }">
                {{ getFormattedTimeZone(row.data.start_of_cultivation) }}
              </template>

              <template v-slot:cultivation_cycle="{ row }">
                {{ row.data.cultivation_cycle }}
              </template>

              <template v-slot:doc="{ row }">{{ row.data.doc }}</template>
              <template v-slot:abw_header="{ header }">
                <span>{{ header.label }}</span>
                <span>({{ header.sub_label }})</span>
              </template>

              <template v-slot:biomass="{ row }">
                <p
                  v-upm__units.digitPrecision.formatNumber="
                    getIndividualBiomassUnits(row.data.biomass)
                  "
                ></p>
              </template>
              <template v-slot:survival_percentage="{ row }">
                {{ ftm__formatEmpty(row.data.survival_percentage) }}
              </template>

              <template v-slot:post_larva_stocked="{ row }">
                {{
                  ftm__formatNumber(
                    row.data.post_larva_stocked,
                    getUserCountryCode
                  )
                }}
              </template>
              <template v-slot:abw="{ row }">
                <p
                  v-upm__units.digitPrecision="
                    getIndividualAbwUnits(row.data.abw)
                  "
                ></p>
              </template>
              <template v-slot:biomass_header="{ header }">
                <span>{{ header.label }}</span>
                <span>({{ header.sub_label }})</span>
              </template>
              <template v-slot:fcr="{ row }">
                {{ partialFCRFilter(row.data) }}
              </template>
              <template v-slot:type="{ row }">
                {{ getHarvestType(row.data) }}
              </template>
              <template v-slot:actions="{ row }">
                <er-button
                  type="text"
                  :disabled="disabledEditAndDelete(row.data) || isReadOnly"
                  size="mini"
                  btnType="edit"
                  :showLabel="true"
                  :showIcon="true"
                  @click="
                    handleClickAction(
                      $event,
                      'edit',
                      $lodash.cloneDeep(row.data)
                    )
                  "
                ></er-button>
                <el-divider direction="vertical"></el-divider>
                <er-button
                  type="text"
                  :disabled="disabledEditAndDelete(row.data) || isReadOnly"
                  size="mini"
                  btnType="delete"
                  :showLabel="true"
                  :showIcon="true"
                  @click="handleRemoveResource($event, row.data)"
                ></er-button>
              </template>
            </er-data-tables>
          </template>
          <template v-slot:pond_name="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="pond_name"
              :withoutHighlightStrings="row.data.pond_name"
            />
          </template>
          <template v-slot:type="{ row }">
            {{ getHarvestType(row.data) }}
          </template>
          <template v-slot:start_of_cultivation="{ row }">
            {{ getFormattedTimeZone(row.data.start_of_cultivation) }}
          </template>

          <template v-slot:date="{ row }">
            {{ getFormattedTimeZone(row.data.date) }}
          </template>
          <template v-slot:doc="{ row }">{{ row.data.doc }}</template>

          <template v-slot:abw_header="{ header }">
            <span>{{ header.label }}</span>
            <span>({{ header.sub_label }})</span>
          </template>

          <template v-slot:post_larva_stocked="{ row }">
            {{
              ftm__formatNumber(row.data.post_larva_stocked, getUserCountryCode)
            }}
          </template>

          <template v-slot:fcr="{ row }">
            {{ partialFCRFilter(row.data) }}
          </template>
          <template v-slot:biomass_header="{ header }">
            <span>{{ header.label }}</span>
            <span>({{ header.sub_label }})</span>
          </template>
          <template v-slot:biomass="{ row }">
            <p
              v-upm__units.digitPrecision.formatNumber="
                getBiomassAndUnits(row.data.biomass)
              "
            ></p>
          </template>
          <template v-slot:survival_percentage="{ row }">
            {{ ftm__formatEmpty(row.data.survival_percentage) }}
          </template>
          <template v-slot:actions="{ row }">
            <er-button
              type="text"
              :disabled="disabledEditAndDelete(row.data) || isReadOnly"
              size="mini"
              btnType="edit"
              :showLabel="true"
              :showIcon="true"
              @click="
                handleClickAction($event, 'edit', $lodash.cloneDeep(row.data))
              "
            ></er-button>
            <el-divider direction="vertical"></el-divider>
            <er-button
              type="text"
              :disabled="disabledEditAndDelete(row.data) || isReadOnly"
              size="mini"
              btnType="delete"
              :showLabel="true"
              :showIcon="true"
              @click="handleRemoveResource($event, row.data)"
            ></er-button>
          </template>
        </er-data-tables>
        <component
          :is="`harvestActionDialog`"
          :show-dialog="dialogVisible"
          :harvest-log="selectedHarvestLog"
          :action-to-perform="actionToPerform"
          @close_dialog="closeDialog"
        ></component>
      </el-row>
      <div class="loading_class" v-else-if="loading"></div>
    </el-row>
  </layout-pondlogs>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin.js";
import harvestActionDialog from "@/components/culture/harvestActionDialog";
import { mapGetters, mapActions } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
// import Loader from "@/components/base/Loader";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import i18n from "@/config/i18n";
export default {
  name: "HarvestYield",
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    notificationMixin,
    userPreferenceMixin,
    datesHandlerMixin
  ],
  components: {
    harvestActionDialog
    // Loader,
  },
  data: function() {
    return {
      date: [],
      dialogVisible: false,
      selectedHarvestLog: "",
      actionToPerform: "",
      pondSearch: "",
      harvestLogs: [],
      harvestYear: "",
      loading: false,
      tableProps: {
        size: "small",
        rowKey: this.getRowKey,
        defaultSort: {
          prop: "pond_name",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      tableProps_expand_table: {
        size: "small",
        rowKey: this.getRowKey,
        defaultSort: {
          prop: "soc",
          order: "descending"
        }
      },
      permissionsData: {
        name: "harvestYield",
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_HARVEST]
      },
      pondIdToHarvestData: {},
      expandloading: "",
      selectedColumns: {},
      selectedHeaders: [],
      pondIdToExpandedRow: {},
      pondWiseFinalHarvestMap: {},
    };
  },

  watch: {
    selectedHeaders: function(newValue, oldValue) {
      this.$nextTick(() => {
        if (this.$refs.harvestyeildtable) {
          this.$refs.harvestyeildtable.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    }
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getUserCountryCode: "getUserCountryCode",
      getPreferredUnits: "getPreferredUnits",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    disabledEditAndDelete() {
      return (harvestRecord) => {
        const isFinalHarvestExistsForSOC = this.pondWiseFinalHarvestMap[harvestRecord.pond_id._id][harvestRecord.start_of_cultivation];
        const partialHarvestCondition = harvestRecord.type === 'PARTIAL' && isFinalHarvestExistsForSOC;
        if (harvestRecord.type === 'FINAL' || partialHarvestCondition) {
            const conditions = [
              this.dhm__dateUtilsLib.differenceInDays(
                this.dhm__getTodayInUserTZ,
                this.dhm__dateUtilsLib.startOfDay(
                  this.dhm__castUserUTCToUTCDateObj(harvestRecord.created_at)
                )
              ) > 7
            ];
            return conditions.every((x) => x);
        } else {
          return false;
        }
      };
    },
    newHarvestLog() {
      return {
        abw: 0,
        biomass: 0,
        date: this.dhm__getTodayInUserTZ,
        fcr: 0,
        pond_name: "",
        pond_id: "",
        post_larva_stocked: "",
        start_of_cultivation: "",
        survival_percentage: 0,
        cumulative_feed: "MANUAL_AND_POND_MOTHER",
        biomass_units: "KG",
        type: "PARTIAL"
      };
    },
    tableData() {
      return this.harvestLogs;
    },
    getMapPondIdPond() {
      return this.$store.getters["pond/getMapPondidPond"];
    },
    getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    },

    labelsAndPlaceHolder() {
      return {
        date_picker: {
          placeholder: this.$t("Comn_hrvst_date")
        },
        search_pond: { placeholder: this.$t("Comn_search") }
      };
    },
    currLocation() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    currLocationId() {
      return this.currLocation._id;
    },
    getHarvestTranslation() {
      return (harvest) => {
        const obj = {
          FINAL: "Comn_final",
          PARTIAL: "Comn_partial"
        };
        const type = i18n.availableLocales.map((locale) => {
          return i18n.tc(obj[harvest.type], 1, locale);
        });

        return type;
      };
    },
    getTotalAbwAndUnits() {
      return (totalAbw) => {
        const totalBiomassandUnits = {
          value: totalAbw || 0,
          previousunits: this.upm__backendUnits.abw_and_growth_units,
          units: this.getPreferredUnits.abw_and_growth_units,
          modifiersParams: { digitPrecision: [2] }
        };
        return totalBiomassandUnits;
      };
    },
    getIndividualAbwUnits() {
      return (individualAbw) => {
        const individualAbwandUnits = {
          value: individualAbw || 0,
          previousunits: this.upm__backendUnits.abw_and_growth_units,
          units: this.getPreferredUnits.abw_and_growth_units,
          modifiersParams: { digitPrecision: [2] },
          displayFormatter: () => {
            return ``;
          }
        };
        return individualAbwandUnits;
      };
    },
    getIndividualBiomassUnits() {
      return (biomass_value) => {
        const individualBiomassandUnits = {
          value: biomass_value || 0,
          previousunits: this.upm__backendUnits.biomass_units,
          units: this.getPreferredUnits.biomass_units,
          modifiersParams: {
            formatNumber: [this.getUserCountryCode],
            digitPrecision: [1]
          },
          displayFormatter: () => {
            return ``;
          }
        };
        return individualBiomassandUnits;
      };
    },

    getBiomassAndUnits() {
      return (biomass_value) => {
        const totalBiomassandUnits = {
          value: biomass_value || 0,
          previousunits: this.upm__backendUnits.biomass_units,
          units: this.getPreferredUnits.biomass_units,
          modifiersParams: {
            formatNumber: [this.getUserCountryCode],
            digitPrecision: [1]
          },
          displayFormatter: () => {
            return ``;
          }
        };
        return totalBiomassandUnits;
      };
    },
    tableColumns() {
      return {
        pond_name: {
          label: this.$t("Comn_pond_name"),
          prop: "pond_name",
          sortable: true,
          sortMethod: (a, b) =>
            this.$commonUtils.alphaNumericComparator(
              a.pond_id.title,
              b.pond_id.title
            ),
          minWidth: 120,
          default: true,
          enableSearch: true,
          searchProperty: "pond_name"
        },
        start_of_cultivation: {
          label: this.$t("Comn_soc"),
          prop: "start_of_cultivation",
          sortable: true,
          minWidth: 80,
          default: true
        },
        date: {
          label: this.$t("Comn_hoc"),
          prop: "date",
          sortable: true,
          minWidth: 100,
          default: true
        },
        doc: {
          label: this.$t("Comn_doc"),
          prop: "doc",
          sortable: true,
          minWidth: 80,
          default: true
        },
        post_larva_stocked: {
          label: this.$t("Pond_pls_stocked"),
          prop: "post_larva_stocked",
          sortable: true,
          width: 120,
          default: false
        },
        abw: {
          label: this.$t("ABW_abw"),
          sub_label: "g",
          prop: "abw",
          sortable: true,
          minWidth: 100,
          default: true
        },
        fcr: {
          label: this.$t("Comn_fcr"),
          prop: "fcr",
          sortable: true,
          minWidth: 90,
          default: true
        },
        biomass: {
          label: `${this.$t("Comn_biomass")}`,
          sub_label: this.$tc(this.getPreferredUnits.biomass_units, 1),
          prop: "biomass",
          sortable: true,
          minWidth: 100,
          default: true
        },
        survival_percentage: {
          label: this.$t("Comn_survival_percentage"),
          prop: "survival_percentage",
          sortable: true,
          minWidth: 120,
          default: true,
        },
        type: {
          label: this.$t("Comn_harvest"),
          prop: "type",
          sortable: true,
          minWidth: 100,
          default: true,
          enableSearch: true,
          searchProperty: "search_type"
        },
        actions: {
          label: this.$t("Comn_actions"),
          default: false,
          minWidth: 150
        }
      };
    },
    expandedTableColumns() {
      return {
        start_of_cultivation: {
          label: this.$t("Comn_soc"),
          prop: "start_of_cultivation",
          sortable: true,
          minWidth: 80
        },
        doc: {
          label: this.$t("Comn_doc"),
          prop: "doc",
          sortable: true,
          minWidth: 70
        },
        cultivation_cycle: {
          label: this.$t("Comn_cycle"),
          prop: "cultivation_cycle",
          sortable: true,
          minWidth: 70
        },
        post_larva_stocked: {
          label: this.$t("Pond_pls_stocked"),
          prop: "post_larva_stocked",
          sortable: true,
          minWidth: 110
        },
        abw: {
          label: this.$t("ABW_abw"),
          sub_label: "g",
          prop: "abw",
          minWidth: 70,
          sortable: true
        },
        fcr: {
          label: this.$t("Comn_fcr"),
          prop: "fcr",
          sortable: true,
          minWidth: 60
        },
        biomass: {
          label: this.$t("Comn_biomass"),
          sub_label: this.$tc(this.getPreferredUnits.biomass_units, 1),
          prop: "biomass",
          minWidth: 100,
          sortable: true
        },
        survival_percentage: {
          label: this.$t("Comn_survival_percentage"),
          prop: "survival_percentage",
          minWidth: 70,
          sortable: true,
        },
        type: {
          label: this.$t("Comn_harvest"),
          prop: "type",
          sortable: true,
          minWidth: 80
        },
        actions: { label: this.$t("Comn_actions"), minWidth: 180 }
      };
    },
    getExpandedTableData() {
      return (harvestLog) => {
        const pondObj = harvestLog.pond_id;
        console.log(harvestLog);
        if (pondObj) {
          const finalHarvestMap = (
            this.pondIdToHarvestData[pondObj._id] || []
          ).reduce((acc, cur) => {
            if (cur.type === 'FINAL') {
              acc[cur.start_of_cultivation] = true;
            }
            return acc;
          }, {})
          this.pondWiseFinalHarvestMap[harvestLog.pond_id._id] = finalHarvestMap;
  console.log("finalHarvestMap", finalHarvestMap);
          const filteredHarvest = (
            this.pondIdToHarvestData[pondObj._id] || []
          ).filter(({ date }) => {
            const condition = this.dhm__dateUtilsLib.isBefore(
              this.dhm__castUserUTCToUTCDateObj(date),
              this.dhm__dateUtilsLib.utcToZonedTime(
                this.dhm__castUserUTCToUTCDateObj(this.date[1]),
                this.getUserTimeZoneString
              )
            );

            console.log(condition);
            return condition;
          });

          return filteredHarvest;
        }
        return [];
      };
    },
    getCurrYear() {
      return this.dhm__getTodayInUserTZ.getFullYear();
    },

    pickerOptions() {
      const arrYears = [this.getCurrYear - 2011]
        .reduce((acc, curr) => {
          for (let i = 0; i < curr; i++) {
            acc.push(2012 + i);
          }
          return acc;
        }, [])
        .sort((a, b) => b - a);
      return {
        disabledDate: (time) => {
          return time.getTime() > this.dhm__getTodayInUserTZ.getTime();
        },
        shortcuts: [
          {
            no: 1,
            unit: "days",
            text: this.$t("Comn_yesterday"),
            type: "date",
            includeToday: false
          },
          {
            no: 6,
            unit: "days",
            text: this.$t("last_7days"),
            type: "date",
            includeToday: true
          },
          {
            no: 14,
            unit: "days",
            text: this.$t("last_15days"),
            type: "date",
            includeToday: true
          },
          {
            no: 29,
            unit: "days",
            text: this.$t("last_30days"),
            type: "date",
            includeToday: true
          },
          {
            no: 3,
            unit: "months",
            text: this.$t("last_3months"),
            type: "date",
            includeToday: true
          },
          {
            no: 6,
            unit: "months",
            text: this.$t("last_6months"),
            type: "date",
            includeToday: true
          },
          ...arrYears.map((x) => ({
            no: x,
            unit: "years",
            text: "" + x,
            type: "date",
            includeToday: true
          }))
        ].map((shortCut) => {
          return {
            text: shortCut.text,
            onClick: (picker) => {
              let end = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
                this.getUserTimeZoneString
              );
              let start = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
                this.getUserTimeZoneString
              );
              if (shortCut.unit === "years") {
                start = this.dhm__dateUtilsLib.setYear(
                  this.dhm__dateUtilsLib.startOfYear(start),
                  shortCut.no
                );

                end = this.dhm__dateUtilsLib.setYear(
                  this.dhm__dateUtilsLib.endOfYear(end),
                  shortCut.no
                );
                if (
                  this.dhm__dateUtilsLib.getYear(this.dhm__getTodayInUserTZ) ===
                  shortCut.no
                ) {
                  end = this.dhm__getTodayInUserTZ;
                }
                picker.$emit("pick", [start, end]);
              } else {
                start = this.dhm__dateUtilsLib.subtract(end, {
                  [shortCut.unit]: shortCut.no
                });
                if (!shortCut.includeToday) {
                  end = this.dhm__dateUtilsLib.subtract(end, { days: 1 });
                }
                picker.$emit("pick", [start, end]);
              }
            }
          };
        })
      };
    },
    sortMethodForColumns: function() {
      return {
        pond_name: this.$commonUtils.alphaNumericComparator
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next();
  },
  mounted() {
    this.harvestYear = "" + this.getCurrYear;
    this.initComponent();
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchInput() {
      if (this.pondSearch !== '') {
        this.mixPanelEventGenerator({ eventName: "Yield Table - Search" });
      }
    },
    handelColumnSave() {
      this.mixPanelEventGenerator({ eventName: "Yield Table - Column Selector - Save" });
    },
    getRowKey(row) {
      return row._id;
    },
    getHarvestType(harvest) {
      const obj = {
        FINAL: this.$t("Comn_final"),
        PARTIAL: this.$t("partial")
      };
      return obj[harvest.type];
    },
    getFormattedTimeZone(date) {
      return this.dhm__formatByTZOnUTCISOWithLocale(
        this.dhm__castUserUTCToUTCISO(date),
        this.upm__getFormatDateString
      );
    },
    async closeDialog(event) {
      this.dialogVisible = false;
      if (event !== "close") {
        this.initComponent();
        Object.values(this.pondIdToExpandedRow).forEach((row) => {
          this.$refs.harvestyeildtable.$refs.dataTables.$refs.elTable.toggleRowExpansion(
            row,
            false
          );
        });
        this.pondIdToHarvestData = {};
        this.pondIdToExpandedRow = {};
        await this.handleExpandChange(this.selectedHarvestLog);
        if (this.actionToPerform === 'add') {
          this.mixPanelEventGenerator({ eventName: "Yield Table - Log Harvest Load - Save" });
        }
        if (this.actionToPerform === 'edit') {
          this.mixPanelEventGenerator({ eventName: "Yield Table - Edit - Save" });
        }
      }
    },
    async initComponent() {
      this.loading = true;
      const from_date = this.getYearDate("" + this.getCurrYear, "start");
      const to_date = this.dhm__dateUtilsLib.formatTZ(
        this.dhm__getTodayInUserTZ,
        this.dhm__dateUtilsLib.isoFormatString
      );
      this.date = [from_date, to_date];
      try {
        await this.$store.dispatch("pond/fetchAllPonds", {
          location_id: this.currLocation._id,
          status: ["ACTIVE"],
          get_all: true
        });
        const harvestLogs = await this.$store.dispatch(
          "pond/fetchAllHarvestLogs",
          {
            get_all: true,
            recent_record: true,
            location_id: this.currLocation._id,
            from_date,
            to_date
          }
        );
        this.harvestLogs = this.$lodash.cloneDeep(harvestLogs).map((x) => {
          x.pond_name = x.pond_id.title;
          x.search_type = this.getHarvestTranslation(x);
          return x;
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.harvestyeildtable) {
            this.$refs.harvestyeildtable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    async handleDateChange(updatedDateRange) {
      Object.values(this.pondIdToExpandedRow).forEach((row) => {
        this.$refs.harvestyeildtable.$refs.dataTables.$refs.elTable.toggleRowExpansion(
          row,
          false
        );
      });
      this.mixPanelEventGenerator({ eventName: "Yield Table - Date Filter" });
      this.pondIdToHarvestData = {};
      this.pondIdToExpandedRow = {};
      if (updatedDateRange != null && updatedDateRange.length > 0) {
        this.loading = true;
        try {
          const harvestLogs = await this.$store.dispatch(
            "pond/fetchAllHarvestLogs",
            {
              location_id: this.currLocation._id,
              recent_record: true,
              from_date: updatedDateRange[0] + "T00:00:00.000Z",
              to_date: updatedDateRange[1] + "T23:59:59.999Z"
            }
          );
          this.harvestLogs = this.$lodash.cloneDeep(harvestLogs).map((x) => {
            x.pond_name = x.pond_id.title;
            x.search_type = this.getHarvestTranslation(x);
            return x;
          });
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      } else {
        this.initComponent();
      }
    },
    async handleExpandChange(row) {
      this.expandloading = row.pond_id._id;
      try {
        if (this.pondIdToHarvestData[row.pond_id._id]) return;
        const expandTableData = await this.$store.dispatch(
          "pond/fetchAllHarvestLogs",
          {
            get_all: true,
            pond_id: row.pond_id._id,
            location_id: this.currLocation._id,
            from_date: this.date[0],
            to_date: this.date[1]
          }
        );
        this.$set(this.pondIdToExpandedRow, row.pond_id._id, row);
        this.$set(this.pondIdToHarvestData, row.pond_id._id, expandTableData);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.expandloading = "";
      }
    },
    handleClickAction(event, actionToPerform, harvestObj) {
      try {
        this.$gblUAMCanUserEdit(this.permissionsData);
        this.dialogVisible = true;
        this.actionToPerform = actionToPerform;
        this.selectedHarvestLog = harvestObj;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async handleRemoveResource(event, harvestLog) {
      try {
        this.$gblUAMCanUserDelete(this.permissionsData);
        await this.nofm__deleteConfirmation(
          `${this.$t("Comn_harvest")} ${this.$t("details")}`,
          harvestLog.pond_name
        );

        this.loading = true;
        await this.$store.dispatch("pond/deleteHarvestLog", harvestLog._id);
        this.nofm__notifySuccess({
          msgLangkey: {
            title: "Comn_delete_success_msg",
            count: 0,
            child: {
              item: this.$t("Comn_yield"),
              source: harvestLog.pond_name
            }
          }
        });
        this.mixPanelEventGenerator({ eventName: "Yield Table - Delete" });
        await this.initComponent();
        Object.values(this.pondIdToExpandedRow).forEach((row) => {
          this.$refs.harvestyeildtable.$refs.dataTables.$refs.elTable.toggleRowExpansion(
            row,
            false
          );
        });
        this.pondIdToHarvestData = {};
        this.pondIdToExpandedRow = {};
        await this.handleExpandChange(harvestLog);
      } catch (err) {
        // if (err !== "cancel") {
        //   this.ehm__errorMessages(err, true);
        // }
        const errors = [];
        if (err.response) {
          errors.push({ message: err.response.data.error_code === 'FINAL_HARVEST_CANNOT_BE_DELETED' ? this.$t('Final_harvest_cannot_be_deleted') : err.response.data.message });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
        // this.loadPondsList();
      }
    },
    async ehm__error400Handler(err) {
      const errorCodeToTranslation = {
        HARVEST_DETAILS_ADDED_MORE_THAN_7_DAYS: this.$t(
          "final_harvest_cannot_delete"
        )
      };
      await this.ehm__errorFailToSave([
        {
          message:
            errorCodeToTranslation[err.response.data.error_code] ||
            this.$t("Something_went_wrong_try_again")
        }
      ]);
    },
    handleChangeInColumnSelection(selectedColumns) {
      this.selectedHeaders = selectedColumns;
    },
    getYearDate(year, type) {
      const formatUserUTCISO = (date) =>
        this.dhm__formatTZ(date, this.dhm__dateUtilsLib.isoFormatString);
      const setYear = (year) => {
        return this.dhm__dateUtilsLib.setYear(this.dhm__getTodayInUserTZ, year);
      };
      if (year === this.getCurrYear && type === "end") {
        return formatUserUTCISO(this.dhm__getTodayInUserTZ);
      } else if (type === "start") {
        return formatUserUTCISO(
          this.dhm__dateUtilsLib.startOfYear(setYear(year))
        );
      } else if (type === "end") {
        return formatUserUTCISO(
          this.dhm__dateUtilsLib.endOfYear(setYear(year))
        );
      }
    },
    async handleChangeInYear(selectedYear) {
      const from_date = this.getYearDate(selectedYear, "start");
      const to_date = this.getYearDate(selectedYear, "end");
      Object.values(this.pondIdToExpandedRow).forEach((row) => {
        this.$refs.harvestyeildtable.$refs.dataTables.$refs.elTable.toggleRowExpansion(
          row,
          false
        );
      });
      this.pondIdToHarvestData = {};
      this.pondIdToExpandedRow = {};
      try {
        const harvestLogs = await this.$store.dispatch(
          "pond/fetchAllHarvestLogs",
          {
            get_all: true,
            recent_record: true,
            location_id: this.currLocation._id,
            from_date,
            to_date
          }
        );
        this.harvestLogs = harvestLogs;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    partialFCRFilter(data) {
      if (data.type === "PARTIAL" || !data.fcr) {
        return "-";
      } else {
        return data.fcr.toFixed(2);
      }
    }
  }
};
</script>

<style lang="scss">
.culture-yield {
  display: flex;
  @include responsiveProperty(--table-gutter, 167px, 170px, 186px);
  .header-toolbar {
    & > *:not(:last-child) {
      margin-right: 10px;
    }
    .er-button {
      height: 28px !important;
      @include responsiveProperty(height, 28px, 28px, 30px, true);
    }
    .er-column-selector .btn-column-selector {
      display: flex;
      align-items: center;
    }
  }
  .input-search-pond {
    width: 9rem;
    .el-input__inner {
      @include responsiveProperty(height, 28px, 28px, 30px);
      @include responsiveProperty(line-height, 28px, 28px, 30px);
    }
  }
  &.culture-yield-scroll {
    height: 100%;
  }
  .culture-yield-scroll-view {
    padding-right: 10px;
  }
  .culture-yield-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .table--primary {
    .el-table {
      border-radius: 10px;
      box-shadow: 0px 1px 6px #00000029;
      .cell {
        text-align: center;
        font-size: 13px;
        .el-tag {
          font-size: 13px;
        }
      }
      th.is-leaf {
        border-bottom: unset;
      }
      .el-table__header {
        background-color: #44a1ff;
        tr {
          background-color: transparent;
          color: white;
        }
        th {
          background-color: transparent;
          border-bottom: unset;
          &:nth-last-child(2) {
            .cell {
              border-right: none;
            }
          }
          & > .cell {
            display: inline-block;
            -webkit-box-sizing: border-box;
            border-right: 1px solid white;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .is-sortable {
    &.ascending .sort-caret.ascending {
      border-bottom-color: #ffffff;
    }
    &.descending .sort-caret.descending {
      border-top-color: #ffffff;
    }
  }
  .el-pagination {
    margin-top: 15px;
    vertical-align: middle;
    .el-pagination__sizes {
      .el-input--mini {
        .el-input__icon {
          line-height: 22px;
        }
        .el-input__inner {
          height: 22px;
          line-height: 22px;
        }
      }
    }
  }
  .er-button.el-button--mini:not(.is-circle) {
    @include responsiveProperty(padding, 7px 10px, 6px 10px, 5px 10px);
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .loading_class {
    @include responsiveProperty(min-height, 79vh, 82vh, 85vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
}
</style>
